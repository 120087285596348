import { createAction, props } from '@ngrx/store';
import { ClientGridItem } from '../../../models/client-grid-item.interface';

export const search = createAction(
  '[CRM Overview Container] Search',
  props<{ searchText: string }>(),
);
export const searchSuccess = createAction(
  '[CRM Overview Container] Search success',
  props<{ data: ClientGridItem[] }>(),
);
export const searchError = createAction('[CRM Overview Container] Seach error');

export const exportCsv = createAction('[CRM Overview Container] Export CSV');
export const importCsv = createAction(
  '[CRM Overview Container] Import CSV',
  props<{ file: File }>(),
);
export const createClient = createAction(
  '[CRM Overview Container] Create Client',
);
export const editClient = createAction(
  '[CRM Overview Container] Edit Client',
  props<{ id: number }>(),
);
export const deleteClient = createAction(
  '[CRM Overview Container] Delete Client',
  props<{ id: number }>(),
);

export const deleteClientSuccess = createAction(
  '[CRM Overview Container] Delete Client success',
);

export const getCsvTemplateClick = createAction(
  '[CRM Overview Container] Export CSV Template',
);
